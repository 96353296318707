import { create } from '@storybook/theming';

export default create({
  base: 'light',
  brandTitle: 'TimeLog Design Guide',
  brandUrl: 'https://timelog.com',
  brandImage: 'https://www.timelog.com/gfx/logo.svg',
  colorPrimary: '#d24186',
  colorSecondary: '#8aa9e3',
  appBg: '#e9f1ff',
});
